// GroceryList.js
import React, { useEffect, useState } from 'react';
import { db } from './firebase';
import { setDoc, getDoc, doc } from "firebase/firestore";
import { queryGPT } from './Gpt';
import Suggestion from './Suggestion';
import LoadingSpinner from './LoadingSpinner';

import './GroceryList.css';

const GroceryList = ({ userEmail }) => {
  const [foods, setFoods] = useState([]);
  const [newFoodName, setNewFoodName] = useState('');
  const [newFoodExpiration, setNewFoodExpiration] = useState('');
  const [gptResponse, setGptResponse] = useState('Nothing so far...');
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    console.log("Querying firestore!");

    const accessUserRecords = async () => {
      const userRef = doc(db, "users", userEmail);
      const user = await getDoc(userRef);

      if (!user.exists()) {
        console.log(`${userEmail} wasn't found, adding now!`);
        // Add a new document in collection "cities"
        await setDoc(doc(db, "users", userEmail), {
          items: [],
        });
      }

      setFoods((await getDoc(userRef)).data().items);
    }

    accessUserRecords();
  }, [userEmail]);

  const handleNameChange = e => {
    setNewFoodName(e.target.value);
  };

  const handleDateChange = e => {
    setNewFoodExpiration(e.target.value);
  };

  const handleAddFood = async () => {
    if (newFoodName.trim() === '' || newFoodExpiration.trim() === '') {
      alert("You must enter a valid name and date!");
      return;
    }

    // Don't add if our item already exists
    if (foods.some(food => food.name === newFoodName)) {
      return;
    }

    console.log("Writing a new item!");
    setIsBusy(true);
    const appended = [...foods, { name: newFoodName, date: newFoodExpiration }];
    setFoods(appended);

    const userRef = doc(db, "users", userEmail);
    await setDoc(userRef, { items: appended });

    setIsBusy(false);
    setNewFoodName('');
    setNewFoodExpiration('');
  };

  const handleDeleteFood = async (foodName) => {
    console.log("Deleting an item!");
    setIsBusy(true);
    const filtered = foods.filter(food => food.name !== foodName);
    setFoods(filtered);

    const userRef = doc(db, "users", userEmail);
    await setDoc(userRef, { items: filtered });
    setIsBusy(false);
  }

  return (
    <div>
      <h1>Items in Fridge:</h1>
      {foods.map((food, index) => (
        <p key={index}>
          <span>{food.name}</span> (expires <span>{food.date}</span>)
          <span className='delete-button' onClick={() => { handleDeleteFood(food.name) }}> Delete </span>
        </p>
      ))}
      <div className='todo-input'>
        <input
          type="text"
          placeholder="Grocery Item"
          value={newFoodName}
          onChange={handleNameChange}
        />
        <input
          type="date"
          value={newFoodExpiration}
          onChange={handleDateChange}
        />
        <button onClick={handleAddFood}>Add</button>
      </div>

      <div className='submit-button'>
        {!isBusy && (
          <button onClick={async e => {
            e.preventDefault();
            setIsBusy(true);
            await queryGPT(foods, setGptResponse);
            setIsBusy(false);
          }}>
            Suggest Recipes!
          </button>
        )}
        {isBusy && <LoadingSpinner />}
      </div>

      <div className='suggestion-area'>
        <Suggestion quote={gptResponse} />
      </div>
    </div>
  );
};

export default GroceryList;

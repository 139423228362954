// Gpt.js

import axios from 'axios';

const getQuery = (today, allIngredientsStr) => {
    return `Assume that today is ${today}. Suggest a few dishes for me to make based on the following ingredients I have left in my fridge:
    ${allIngredientsStr}
    
    Make sure to prioritize using ingredients with the earliest expiration dates listed. 
    
    To make the response parseable, return the dishes as a JSON object with the following schema:
    {
        "recipes": [
            {
                "dish-name": "dish-example-1-name",
                "dish-ingredients": "dish-example-1-ingredients",
                "dish-preparation-steps": ["1. step-1", "2. step-2", ...]
            },
            {
                "dish-name": "dish-example-2-name",
                "dish-ingredients": "dish-example-2-ingredients",
                "dish-preparation-steps": ["1. step-1", "2. step-2", ...]
            }
        ]
    }

    To be clear, the object should have one top-level field "recipes", a list of object with the following properties:
    - dish-name: the dish's name (string)
    - dish-ingredients: the dish's ingredients (string[])
    - dish-preparation-steps: steps to cook the dish (string[])
        - as a formality, please prefix each step with the step number followed by a period
    `;
}

export const queryGPT = (foods, responseSetter) => {
    if (foods.length === 0) {
        alert('You have no items to cook with!')
        return;
    }

    const allIngredients = [];

    foods.forEach(item => {
        allIngredients.push(" - " + item.name + " expires by " + item.date);
    });

    const date = new Date();
    let currentDay = String(date.getDate()).padStart(2, '0');
    let currentMonth = String(date.getMonth() + 1).padStart(2, "0");
    let currentYear = date.getFullYear();
    let today = `${currentYear}-${currentMonth}-${currentDay}`;

    const allIngredientsStr = allIngredients.join('\n');
    console.log("I'm using the following ingredients:\n", allIngredientsStr);

    const fullQuery = getQuery(today, allIngredientsStr);

    const bodyFormData = new FormData();
    bodyFormData.append('query', fullQuery);

    axios({
        method: "post",
        url: process.env.REACT_APP_BACKEND_ENDPOINT,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
        crossDomain: true
    })
        .then(res => {
            // handle success
            responseSetter(res.data);
        })
        .catch(err => {
            // handle error
            alert("The application is overloaded, try again in a few minutes...");
            console.log(err);
        });
}
import React from 'react';

const Suggestion = ({ quote }) => {
  if (!quote.recipes) {
    return <div className="card"> {quote} </div>
  }

  return (
    <div className="card">
      {quote.recipes.map((d, idx) => {
        return (
          <div key={idx} className="card-body">
            <p><u>{d['dish-name']}</u></p>
            <p><b>({d['dish-ingredients'].join(', ')})</b></p>
            {d['dish-preparation-steps'].map(step => {
              return <p> {step} </p>
            })}
          </div>);
      })}
    </div>
  );
};

export default Suggestion;
// App.js
import React, { useState } from 'react';
import { signInWithPopup, signOut as firebaseSignOut } from "firebase/auth";
import { auth, provider } from './firebase';
import GroceryList from './GroceryList';

import './App.css';
import './GroceryList.css';

function App() {
  const [user, setUser] = useState({
    name: localStorage.getItem("name"),
    email: localStorage.getItem("email"),
    profilePic: localStorage.getItem("profilePic"),
  });

  const handleSignIn = (result) => {
    const { displayName: name, email, photoURL: profilePic } = result.user;
    const newUser = { name, email, profilePic };

    localStorage.setItem("name", name);
    localStorage.setItem("email", email);
    localStorage.setItem("profilePic", profilePic);

    setUser(newUser);
  };

  const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then(handleSignIn)
      .catch(err => {
        console.log(err);
      }
      );
  }

  const handleSignOut = () => {
    // Clear local storage
    localStorage.removeItem("name");
    localStorage.removeItem("profilePic");

    // Clear user state
    setUser({
      name: null,
      profilePic: null,
    });

    // Sign out user
    firebaseSignOut(auth)
      .then(() => {
        console.log("User signed out successfully.");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="App">
      {!user.name && (
        <button className="login-with-google-btn" onClick={signInWithGoogle}>
          Sign In With Google
        </button>
      )}
      {user.name && (
        <div>
          <p>Signed in as: <b>{user.email}</b></p>
          <img src={user.profilePic} alt='' />
          <h3>
            <button className="logout-button" onClick={handleSignOut}>
              Sign Out
            </button>
          </h3>
        </div>
      )}

      {user.name && <GroceryList userEmail={user.email} />}
    </div>
  );
}

export default App;